<template>
  <div class="readme-article">
    <h1 id="引流获客">二、引流获客</h1>
    <h2 id="渠道活码">1.渠道活码</h2>
    <h3 id="活码分组">1.1活码分组</h3>
    <p>
      渠道活码个性化分组，管理员可新增、编辑、删除分组；点击分组列表自动筛选该分组活码。
    </p>
    <p>
      <img src="@/assets/img/scrm/2-1.png" />
    </p>
    <h3 id="活码列表">1.2活码列表</h3>
    <p>
      查看已添加的渠道活码列表，包含活码地址、活码名称、创建时间等信息，管理员可新增、编辑、删除活码。
    </p>
    <p>
      <img src="@/assets/img/scrm/2-2.png" />
    </p>
    <h3 id="活码编辑">1.3活码编辑</h3>
    <p>
      新增编辑活码，至少需要配置一个客服人员，配置信息包括活码名称，活码分组，客服人员，员工添加人数上限，欢迎语等。
    </p>
    <p>
      <img src="@/assets/img/scrm/2-3.png" />
    </p>
    <h2 id="搜索添加">2.搜索添加</h2>
    <p>
      员工收到客户的好友验证邀请，可在个人微信和企业微信同时添加好友客户。
    </p>
    <p>
      <img src="@/assets/img/scrm/2-4.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm2-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>